import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { AuthContext } from "api/AuthContext";
import { NameIcon } from "common/Reusables";
import { Modal } from "components/CustomModal";
import { getMethod } from "hooks/getHook";
import moment from "moment";
import React, { memo, useContext } from "react";
import { useQuery } from "react-query";
import { formatDate } from "../util";
import FilePreview from "./FilePreview";
import Message from "./Message";
import ReceiverMessge from "./ReceiverMessge";
import "./style.css";

const Messages = memo(
  ({
    activeUser,
    messages,
    isLoading,
    handleChatActions,
    isGroup,
    parentMessage,
    setParentMessage,
    activeGroup,
    editMessage,
    handleSendMessage,
    editorTipTap,
    openEmoji,
    setOpenEmoji,
    messageId,
    setMessageId,
    isFetchingNextPage,
    firstElementRef,
  }) => {
    const { user } = useContext(AuthContext);
    const [params, setParams] = React.useState(null);
    const [imageUrl, setImageUrl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    function getFileExtension(filename) {
      // Find the last occurrence of a dot in the filename
      const lastDotIndex = filename.lastIndexOf(".");

      // Check if there is a dot and it's not the first character
      if (lastDotIndex > 0 && lastDotIndex < filename.length - 1) {
        return filename.substring(lastDotIndex + 1).toLowerCase(); // Return the extension in lowercase
      }

      // Return null if there's no valid extension
      return null;
    }

    const fileExtension = ["jpg", "jpeg", "png", "gif"];
    function getFileExtension(filename) {
      // Find the last occurrence of a dot in the filename
      const lastDotIndex = filename.lastIndexOf(".");

      // Check if there is a dot and it's not the first character
      if (lastDotIndex > 0 && lastDotIndex < filename.length - 1) {
        return filename.substring(lastDotIndex + 1).toLowerCase(); // Return the extension in lowercase
      }

      // Return null if there's no valid extension
      return null;
    }
    const downloadFile = useQuery(
      [
        "get-files",
        {
          route: `/chat_uploads/`,
          params: params,
        },
      ],
      getMethod,
      {
        enabled: Boolean(params),
        onSuccess: (blob) => {
          console.log(blob, "blob");

          setImageUrl(blob);
          // const a = document.createElement("a");
          // a.href = blob;
          // a.target = "_blank";
          // a.click();
        },
      }
    );

    const isEmpty = Object.keys(messages).length === 0;
    if (isLoading)
      return (
        <Box pt="24px" position="relative">
          <CircularProgress />
        </Box>
      );
    return (
      <Box pt="24px" position="relative">
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: "column",
            paddingBottom: "2rem",
          }}
        >
          {isEmpty ? (
            <Box
              sx={{
                height: "calc(100vh - 300px)",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NameIcon
                scale={2.14}
                height={107}
                width={107}
                first={`${activeGroup?.name[0]}`}
              />

              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: 600,
                  fontFamily: "Black Sans",
                  color: "#000",
                }}
              >
                {activeGroup?.name}
              </Typography>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Black Sans",
                  color: "rgba(84, 84, 84, 0.60)",
                }}
              >
                {`          Created on: ${moment(
                  activeGroup?.created_at
                ).format("Do MMMM, YYYY")}`}
              </Typography>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: "Black Sans",
                  color: "rgba(84, 84, 84, 0.60)",
                }}
              >
                {activeGroup?.members?.length} Members
              </Typography>
            </Box>
          ) : (
            // <div>
            //   {isFetchingNextPage && <div>Loading more...</div>}
            //   <div ref={firstElementRef}>Visible</div>
            //   {conversations?.pages.map((page, pageIndex) =>
            //     Object.entries(page.results).map(
            //       ([date, messages], dateIndex) => (
            //         <div key={`${pageIndex}-${dateIndex}`}>
            //           <Typography
            //             style={{
            //               textAlign: "center",
            //               fontSize: "12px",
            //               lineHeight: "18px",
            //               fontWeight: 400,
            //               fontFamily: "Black Sans",
            //               marginTop: "20px",
            //             }}
            //           >
            //             {formatDate(date)}
            //           </Typography>
            //           {messages.map((message, index) => (
            //             <div key={message.id}>
            //               {message?.sender?.id === user?.id ||
            //               message?.sender === user?.id ? (
            //                 <Message
            //                   handleOpen={() => {
            //                     setOpen(true);
            //                     setParams({
            //                       key: message?.file,
            //                     });
            //                   }}
            //                   {...{
            //                     message,
            //                     messages,
            //                     index,
            //                     activeUser,
            //                     user,
            //                     downloadFile,
            //                     parentMessage,
            //                     setParentMessage,
            //                     handleChatActions,
            //                     editMessage,
            //                     handleSendMessage,
            //                     handleSendMessageToGroup,
            //                     editorTipTap,
            //                     openEmoji,
            //                     setOpenEmoji,
            //                     handleKeyDown,
            //                     messageId,
            //                     setMessageId,
            //                     params,
            //                   }}
            //                 />
            //               ) : (
            //                 <ReceiverMessge
            //                   handleOpen={() => {
            //                     setOpen(true);
            //                     setParams({
            //                       key: message?.file,
            //                     });
            //                   }}
            //                   {...{
            //                     message,
            //                     messages,
            //                     index,
            //                     activeUser,
            //                     user,
            //                     downloadFile,
            //                     isGroup,
            //                     parentMessage,
            //                     setParentMessage,
            //                     handleChatActions,
            //                     params,
            //                   }}
            //                 />
            //               )}
            //             </div>
            //           ))}
            //         </div>
            //       )
            //     )
            //   )}
            // </div>
            <div>
              {isFetchingNextPage && (
                <Box my={2}>
                  <CircularProgress />
                </Box>
              )}
              {Object.entries(messages).map(([date, messages], dateIndex) => (
                <div key={date} ref={dateIndex === 0 ? firstElementRef : null}>
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      lineHeight: "18px",
                      fontWeight: 400,
                      fontFamily: "Black Sans",
                      marginTop: "20px",
                    }}
                  >
                    {formatDate(date)}
                  </Typography>
                  {messages.map((message, index) => (
                    <div>
                      {message?.sender?.id === user?.id ||
                      message?.sender === user?.id ? (
                        <Message
                          handleOpen={() => {
                            setOpen(true);
                            setParams({
                              key: message?.file,
                            });
                          }}
                          {...{
                            message,
                            messages,
                            index,
                            activeUser,
                            user,
                            downloadFile,
                            parentMessage,
                            setParentMessage,
                            handleChatActions,
                            editMessage,
                            handleSendMessage,
                            // handleSendMessageToGroup,
                            editorTipTap,
                            openEmoji,
                            setOpenEmoji,
                            // handleKeyDown,
                            messageId,
                            setMessageId,
                            params,
                          }}
                        />
                      ) : (
                        <ReceiverMessge
                          handleOpen={() => {
                            setOpen(true);
                            setParams({
                              key: message?.file,
                            });
                          }}
                          {...{
                            message,
                            messages,
                            index,
                            activeUser,
                            user,
                            downloadFile,
                            isGroup,
                            parentMessage,
                            setParentMessage,
                            handleChatActions,
                            params,
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </Box>

        {open && (
          <Modal onClick={() => setOpen(false)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                width: "800px",
                height: "95vh",
              }}
            >
              {downloadFile.isLoading ? (
                <CircularProgress color="secondary" />
              ) : fileExtension.includes(
                  params.key.split(".")[1].toLowerCase()
                ) ? (
                <img src={imageUrl} width="800" height="400" alt="oo"></img>
              ) : (
                <FilePreview
                  filePath={imageUrl}
                  fileType={getFileExtension(params.key)}
                  imageUrl={imageUrl}
                />
              )}

              <a
                href={imageUrl}
                target="_blank"
                rel="noreferrer"
                download={params.key}
              >
                <Button
                  variant="outlined"
                  style={{
                    width: "105px",
                    height: "45px",
                    borderRadius: "10px",
                    backgroundColor: "#4069D0",
                    fontFamily: "Black Sans",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "normal",
                    textTransform: "capitalize",
                    marginTop: "20px",
                    color: "#fff",
                  }}
                >
                  Download
                </Button>
              </a>
            </Box>
          </Modal>
        )}
      </Box>
    );
  }
);

export default Messages;
