import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDW9aqYXNOx-JoGuVAQLCa9P1wazp8wKAg",
  authDomain: "myedge-123a4.firebaseapp.com",
  projectId: "myedge-123a4",
  storageBucket: "myedge-123a4.appspot.com",
  messagingSenderId: "866821852495",
  appId: "1:866821852495:web:e763bf5cb37872e7e4893a",
  measurementId: "G-KHPKJQ81K0",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging
export const messaging = getMessaging(firebaseApp);
const playNotificationSound = () => {
  const audio = new Audio("notification-sound.mp3");
  audio.play();
};
export const requestForToken = async () => {
  try {
    // Pass the registered service worker to Firebase Messaging
    const messaging = getMessaging();
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BN_Zkhd6mE_0sFKP7y27imutnOCnYjRqAjPwKLNkGkadf-bG0NMaxxorbyffRfIgi3VeL9aIw2yyYqPPREEeptA", // Use the registered service worker
    });

    if (currentToken) {
      console.log("FCM Token:", currentToken);

      return currentToken;
    } else {
      console.log("No registration token available.");
    }
  } catch (error) {
    console.log("Error getting token:", error);
  }
};

// Handle incoming messages
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const onBackgroundMessage = (callback) => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("message", (event) => {
      if (event.data && event.data.type === "BACKGROUND_MESSAGE") {
        playNotificationSound();
        callback(event.data.payload);
      }
    });
  }
};
